import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    nombreUsuario: '',
    puntitos: 'true'
  },
  mutations: {
    setNombreUsuario(state, nombre) {
      state.nombreUsuario = nombre;
    },
    setPuntitos(state, value) {
        state.puntitos = value;
      }
  },
  actions: {
    actualizarNombreUsuario({ commit }, nombre) {
      commit('setNombreUsuario', nombre);
    },
    actualizarPuntitos({ commit }, value) {
        commit('setPuntitos', value);
      }
  }
});

export default store;