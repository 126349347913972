import Vue from "vue";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueGeolocation from "vue-browser-geolocation";
import GetTextPlugin from 'vue-gettext';
import translations from '/translations.json';
import store from './store'


Vue.use(GetTextPlugin, {
    availableLanguages: {
        en: 'English',
        es: 'Español',
    },
    defaultLanguage: 'en',
    translations: translations,
});

Vue.use(VueGeolocation);

//Vue.prototype.$localhost = "http://localhost:8001";
Vue.prototype.$localhost = "";

Vue.prototype.$idRegistro;


Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    store,
    render: (h) => h(App)
}).$mount("#app");